import Labels from './Labels'
import Controls from './Controls'
import { TitleContainer } from './Segmentor.style'

const Segmentor = ({
  activeFrame,
  activeSegmentationClass,
  activeSegmentation,
  dicom,
  phaseLabels,
  segmentationClasses,
  segmentations,
  setActiveSegmentationClass,
  setActiveFrameIndex,
  setActiveSegmentation,
  setSegmentation,
  useOpenContours,
  setUseOpenContours,
  showOtherSegmentations,
  setShowOtherSegmentations,
}) => {
  return (
    <div>
      <TitleContainer>Segment Dicom</TitleContainer>
      <Labels
        activeFrame={activeFrame}
        activeSegmentationClass={activeSegmentationClass}
        dicom={dicom}
        phaseLabels={phaseLabels}
        segmentationClasses={segmentationClasses}
        setActiveFrameIndex={setActiveFrameIndex}
        setActiveSegmentationClass={setActiveSegmentationClass}
        segmentations={segmentations}
        setSegmentation={setSegmentation}
      />
      <Controls
        activeSegmentation={activeSegmentation}
        useOpenContours={useOpenContours}
        setUseOpenContours={setUseOpenContours}
        setActiveSegmentation={setActiveSegmentation}
        showOtherSegmentations={showOtherSegmentations}
        setShowOtherSegmentations={setShowOtherSegmentations}
      />
    </div>
  )
}

export default Segmentor
