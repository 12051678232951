import { useCallback, useEffect, useMemo, useState } from 'react'
import { IoIosCloudUpload, IoIosWarning, IoMdArrowRoundBack } from 'react-icons/io'
import { useParams } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import useAxios from 'axios-hooks'

import {
  DICOM_LABELING_JOBS,
  FRAMES,
  KEYPOINT_COLLECTION_CLASSES,
  KEYPOINT_COLLECTION_LABELS,
  PHASE_CLASSES,
  PHASE_LABELS,
  QUALITY_LABELS,
  SEGMENTATION_CLASSES,
  SEGMENTATION_LABELS,
  STUDY_LABELING_JOBS,
  VIEW_CLASSES,
  VIEW_LABELS,
} from '../../../constants/api'
import Canvas from './Canvas'
import Loader from './Loader'
import {
  Button,
  CanvasContainer,
  ContentContainer,
  HeaderContainer,
  IconContainer,
  LabelsContainer,
  LabelTag,
  PageContainer,
  RightSideBar,
  SideBarContainer,
  SmallLoaderContainer,
  StatusTag,
  ViewerContainer,
} from './Viewer.style'
import Dicoms from './Dicoms'
import Qualifier from './Qualifier'
import Identifier from './Identifier'
import Keypointer from './Kepointer'
import Segmentor from './Segmentor'
import Phaser from './Phaser'
import { useHotkeys } from 'react-hotkeys-hook'
import SubHeader from './SubHeader'
import { useSelector } from 'react-redux'

const Viewer = () => {
  const user = useSelector((state) => state.auth.user)
  const { annotationSetId, dicomLabelingJobId, studyLabelingJobId } = useParams()

  const [studyId, setStudyId] = useState()

  const [dicomSideBarIsOpen, setDicomSideBarIsOpen] = useState(true)

  const dicomsLabeligJobsParams = useMemo(
    () => ({ study_labeling_job: studyLabelingJobId, paginate: false }),
    [studyLabelingJobId],
  )

  // labels are queries using the study, not the study-labeling-job
  // admin users pass in the annotation-set-id to filter labels for a specific job
  // other users filter for any labels they own, regardless of the annotation set to avoid duplicate labels
  const viewLabelParams = useMemo(
    () => ({
      study: studyId,
      paginate: false,
      annotation_set: user?.isSuperuser ? annotationSetId : null,
    }),
    [annotationSetId, studyId, user?.isSuperuser],
  )
  const qualityLabelParams = useMemo(
    () => ({
      study: studyId,
      paginate: false,
      annotation_set: user?.isSuperuser ? annotationSetId : null,
    }),
    [annotationSetId, studyId, user?.isSuperuser],
  )
  const phaseLabelParams = useMemo(
    () => ({
      study: studyId,
      paginate: false,
      annotation_set: user?.isSuperuser ? annotationSetId : null,
    }),
    [annotationSetId, studyId, user?.isSuperuser],
  )
  const keypointCollectionLabelParams = useMemo(
    () => ({
      study: studyId,
      paginate: false,
      annotation_set: user?.isSuperuser ? annotationSetId : null,
    }),
    [annotationSetId, studyId, user?.isSuperuser],
  )
  const ssegmentationLabelsParams = useMemo(
    () => ({
      study: studyId,
      paginate: false,
      annotation_set: user?.isSuperuser ? annotationSetId : null,
    }),
    [annotationSetId, studyId, user?.isSuperuser],
  )

  const [{ data: studyLabelingJobResponse, loading: studyLabelingJobResponseIsLoading }] = useAxios({
    url: `${STUDY_LABELING_JOBS}${studyLabelingJobId}/`,
  })

  const [{ data: dicomLabelingJobsResponse, loading: dicomLabelingJobsResponseIsLoading }] = useAxios({
    url: DICOM_LABELING_JOBS,
    params: dicomsLabeligJobsParams,
  })

  const [{ data: viewClassesResponse, loading: viewClassesResponseIsLoading }] = useAxios({
    url: VIEW_CLASSES,
    params: { paginate: false },
  })
  const [{ data: phaseClassesResponse, loading: phaseClassesResponseIsLoading }] = useAxios(PHASE_CLASSES)
  const [{ data: keypointCollectionClassesResponse, loading: keypointCollectionClassesResponseIsLoading }] =
    useAxios(KEYPOINT_COLLECTION_CLASSES)
  const [{ data: segmentationClassesResponse, loading: segmentationClassesResponseIsLoading }] =
    useAxios(SEGMENTATION_CLASSES)

  const [{ data: viewLabelsResponse, loading: viewLabelResponseIsLoading }, getViewLabels] = useAxios(
    {
      url: VIEW_LABELS,
      params: viewLabelParams,
    },
    { useCache: false, manual: true },
  )
  const [{ data: phaseLabelsResponse, loading: phaseLabelsResponseIsLoading }, getPhaseLabels] = useAxios(
    {
      url: PHASE_LABELS,
      params: phaseLabelParams,
    },
    { useCache: false, manual: true },
  )
  const [
    { data: keypointCollectionLabelsResponse, loading: keypointCollectionLabelsResponseIsLoading },
    getKeypointCollections,
  ] = useAxios(
    {
      url: KEYPOINT_COLLECTION_LABELS,
      params: keypointCollectionLabelParams,
    },
    { useCache: false, manual: true },
  )
  const [{ data: segmentationLabelsResponse, loading: segmentationLabelsResponseIsLoading }, getSegmentations] =
    useAxios(
      {
        url: SEGMENTATION_LABELS,
        params: ssegmentationLabelsParams,
      },
      { useCache: false, manual: true },
    )

  const [{ data: qualityLabelsResponse, loading: qualityLabelsResponseIsLoading }, getQualityLabels] = useAxios(
    {
      url: QUALITY_LABELS,
      params: qualityLabelParams,
    },
    { useCache: false, manual: true },
  )

  const [activeTool, setActiveTool] = useState('identifier')
  const [activeFrameIndex, setActiveFrameIndex] = useState(0)
  const [dicom, setDicom] = useState([])
  const [frames, setFrames] = useState([])
  const [videoState, setVideoState] = useState(false)
  const [intervalId, setIntervalId] = useState()

  const [dataIsLoading, setDataIsLoading] = useState(false)
  const [dataIsSaving, setDataIsSaving] = useState(false)
  const [errorOccured, setErrorOccured] = useState(false)

  const [viewLabelChangesAreSaved, setViewLabelChangesAreSaved] = useState(true)
  const [qualityLabelChangesAreSaved, setQualityLabelChangesAreSaved] = useState(true)
  const [phaseLabelChangesAreSaved, setPhaseLabelLabelChangesAreSaved] = useState(true)
  const [keypointCollectionChangesAreSaved, setKeypointCollectionLabelLabelsChangesAreSaved] = useState(true)
  const [segmentationChangesAreSaved, setSegmentationChangesAreSaved] = useState(true)
  const [dicomJobChangesAreSaved, setDicomJobChangesAreSaved] = useState(true)

  const [viewLabels, setViewLabels] = useState(null)
  const [qualityLabels, setQualityLabels] = useState(null)
  const [phaseLabels, setPhaseLabels] = useState(null)
  const [keypointCollectionLabels, setKeypointCollectionLabelLabels] = useState(null)
  const [segmentationLabels, setSegmentationLabels] = useState(null)

  const [activeKeypointCollectionClass, setActiveKeypointCollectionClass] = useState(null)
  const [activeSegmentationClass, setActiveSegmentationClass] = useState(null)

  const [showMeasurements, setShowMeasurements] = useState(true)
  const [useOpenContours, setUseOpenContours] = useState(false)
  const [showOtherSegmentations, setShowOtherSegmentations] = useState(false)

  const backToDatasetLink = `/studies/${annotationSetId}`

  const study = studyLabelingJobResponse
  const dicomLabelingJobs = dicomLabelingJobsResponse
  const allDicoms = dicomLabelingJobsResponse?.map((item) => item.dicom)
  const dicoms = allDicoms?.sort((a, b) => a?.index - b?.index)

  const viewClasses = viewClassesResponse
  const phaseClasses = phaseClassesResponse?.results
  const keypointCollectionClasses = keypointCollectionClassesResponse?.results
  const segmentationClasses = segmentationClassesResponse?.results

  const numberOfFrames = frames?.length
  const numberOfSeconds = 1000 / dicom?.framesPerSecond

  const identifierIsActive = activeTool === 'identifier'
  const phaserIsActive = activeTool === 'phaser'
  const keypointerIsActive = activeTool === 'keypointer'
  const segmentorIsActive = activeTool === 'segmentor'
  const identifierIsDisabled = false
  const phaserIsDisabled = false
  const keypointerIsDisabled = false
  const segmentorIsDisabled = false

  const framesParams = useMemo(() => {
    if (!studyId || !dicom?.uuid) return null
    return { study: studyId, dicom: dicom.uuid, paginate: false }
  }, [studyId, dicom])

  const [{ data: framesResponse, loading: framesResponseIsLoading }] = useAxios(
    framesParams ? { url: FRAMES, params: framesParams } : null,
  )

  useEffect(() => {
    if (studyLabelingJobResponse) {
      setStudyId(studyLabelingJobResponse?.studyUuid)
    }
  }, [studyLabelingJobResponse])

  const checkDicomForLabeledViewLabel = useCallback(
    (dicom) => {
      const viewLabel = viewLabels?.find((viewLabel) => viewLabel?.dicom === dicom?.uuid)
      const qualityLabel = qualityLabels?.find((qualityLabel) => qualityLabel?.dicom === dicom?.uuid)
      const viewLabelIsLabeled = !!(viewLabel?.viewClass && qualityLabel?.qualityClass)
      const viewLabelIsRequired = true
      return {
        viewLabelIsLabeled,
        viewLabelIsRequired,
      }
    },
    [qualityLabels, viewLabels],
  )

  const checkDicomForLabeledPhases = useCallback(
    (dicom) => {
      // get the required phases based on the view-label
      const viewLabel = viewLabels?.find((viewLabel) => viewLabel?.dicom === dicom?.uuid)
      const requiredPhases = phaseClasses
        ?.filter((phaseClass) => phaseClass?.viewClasses?.includes(viewLabel?.viewClass))
        ?.map((phaseClass) => phaseClass?.value)

      // check if phases are required
      const phaseLabelsAreRequired = !!requiredPhases?.length

      // if there are no required phases, return
      if (!phaseLabelsAreRequired)
        return {
          phaseLabelsAreLabeled: false,
          phaseLabelsAreRequired: false,
        }

      // get the phase labels for the given dicom
      const phaseLabelsForDicom = phaseLabels
        ?.filter((phaseLabel) => phaseLabel?.dicom === dicom?.uuid)
        ?.map((phaseLabel) => phaseLabel?.phaseClass)

      // check that all phases have been labeled, return false if any are missing
      for (let i = 0; i < requiredPhases?.length; i++) {
        const requiredPhase = requiredPhases[i]
        if (!phaseLabelsForDicom?.includes(requiredPhase))
          return {
            phaseLabelsAreLabeled: false,
            phaseLabelsAreRequired: true,
          }
      }

      // if all required phases are labeled, return true
      return {
        phaseLabelsAreLabeled: true,
        phaseLabelsAreRequired: true,
      }
    },
    [phaseClasses, phaseLabels, viewLabels],
  )

  const checkDicomForLabeledKeypoints = useCallback(
    (dicom) => {
      const viewLabel = viewLabels?.find((viewLabel) => viewLabel?.dicom === dicom?.uuid)
      const viewClassUuid = viewLabel?.viewClass

      const requiredPhases = phaseClasses
        ?.filter((phaseClass) => phaseClass?.viewClasses?.includes(viewClassUuid))
        ?.map((phaseClass) => phaseClass?.value)

      const requiredKeypointCollectionClasses = keypointCollectionClasses?.filter((keypointCollectionClass) =>
        keypointCollectionClass?.viewClasses?.includes(viewClassUuid),
      )
      const keypointCollectionsAreRequired = !!requiredKeypointCollectionClasses?.length

      // if there are no required keypoint collections, return
      if (!keypointCollectionsAreRequired)
        return {
          keypointCollectionLabelsAreLabeled: false,
          keypointCollectionLabelsAreRequired: false,
        }

      // check if phases are required
      const phaseLabelsAreRequiredForDicom = !!requiredPhases?.length

      // for each keypoint collection class, check if it is labeled
      for (let i = 0; i < requiredKeypointCollectionClasses?.length; i++) {
        const keypointCollectionClass = requiredKeypointCollectionClasses[i]

        const requiredPhasesForKeypointCollection = keypointCollectionClass?.phases // TODO: update to phaseClasses
        const phasesAreRequiredForKeypointCollection = !!requiredPhasesForKeypointCollection?.length

        // case a) if phases are required, check if keypoint collection labels exist for each phase
        if (phaseLabelsAreRequiredForDicom && phasesAreRequiredForKeypointCollection) {
          for (let j = 0; j < requiredPhasesForKeypointCollection?.length; j++) {
            // first, get the phase label for the required phase
            const requiredPhaseValue = requiredPhasesForKeypointCollection[j]?.value
            const phaseLabel = phaseLabels?.find(
              (phaseLabel) => phaseLabel?.dicom === dicom?.uuid && phaseLabel?.phaseClass === requiredPhaseValue,
            )

            // next, look for the keypoint collection label for the required phase
            const keypointCollectionLabel = keypointCollectionLabels?.find(
              (keypointCollectionLabel) =>
                keypointCollectionLabel?.frame === phaseLabel?.frame &&
                keypointCollectionLabel?.keypointCollectionClass === keypointCollectionClass?.uuid,
            )

            // if the keypoint collection label does not exist, return false
            if (!keypointCollectionLabel)
              return {
                keypointCollectionLabelsAreLabeled: false,
                keypointCollectionLabelsAreRequired: true,
              }

            // if the keypoint collection is not fully visible, skip it
            if (keypointCollectionLabel?.isFullyVisible === false) continue

            // if not all keypoints are labeled, return false
            const requiredKeypoints = keypointCollectionClass?.keypointClasses
            const keypointLabels = keypointCollectionLabel?.keypointLabels
            const keypointLabelsAreLabeled =
              keypointLabels?.length > 0 &&
              keypointLabels?.length === requiredKeypoints?.length &&
              keypointLabels?.every((keypoint) => keypoint?.x && keypoint?.y)
            if (!keypointLabelsAreLabeled)
              return {
                keypointCollectionLabelsAreLabeled: false,
                keypointCollectionLabelsAreRequired: true,
              }
          }
        }

        // case b) if phases are not required, check if keypoint collection labels exist for the dicom
        else {
          const keypointCollectionLabel = keypointCollectionLabels?.find(
            (keypointCollectionLabel) =>
              keypointCollectionLabel?.dicom === dicom?.uuid &&
              keypointCollectionLabel?.keypointCollectionClass === keypointCollectionClass?.uuid,
          )

          // if the keypoint collection label does not exist, return false
          if (!keypointCollectionLabel)
            return {
              keypointCollectionLabelsAreLabeled: false,
              keypointCollectionLabelsAreRequired: true,
            }

          // if the keypoint collection is not fully visible, skip it
          if (keypointCollectionLabel?.isFullyVisible === false) continue

          // if not all keypoints are labeled, return false
          const requiredKeypoints = keypointCollectionClass?.keypointClasses
          const keypointLabels = keypointCollectionLabel?.keypointLabels
          const keypointLabelsAreLabeled =
            keypointLabels?.length > 0 &&
            keypointLabels?.length === requiredKeypoints?.length &&
            keypointLabels?.every((keypoint) => keypoint?.x && keypoint?.y)
          if (!keypointLabelsAreLabeled)
            return {
              keypointCollectionLabelsAreLabeled: false,
              keypointCollectionLabelsAreRequired: true,
            }
        }
      }

      return {
        keypointCollectionLabelsAreLabeled: true,
        keypointCollectionLabelsAreRequired: true,
      }
    },
    [viewLabels, phaseClasses, keypointCollectionClasses, phaseLabels, keypointCollectionLabels],
  )

  const checkDicomForLabeledSegmentations = useCallback(
    (dicom) => {
      const viewLabel = viewLabels?.find((viewLabel) => viewLabel?.dicom === dicom?.uuid)
      const viewClassUuid = viewLabel?.viewClass

      // TODO: dynamically set this on the class table, see keypoint collection classes
      let requiredPhases = null
      if (dicom?.mediaType?.includes('Video')) requiredPhases = ['Systolic', 'Diastolic']

      const requiredSegmentationClasses = segmentationClasses?.filter((segmentationClass) =>
        segmentationClass?.viewClasses?.includes(viewClassUuid),
      )
      const segmentationsAreRequired = !!requiredSegmentationClasses?.length

      // if there are no required segmentation classes, return
      if (!segmentationsAreRequired)
        return {
          segmentationsAreLabeled: false,
          segmentationsAreRequired: false,
        }

      // check if phases are required
      const phaseLabelsAreRequiredForDicom = !!requiredPhases?.length

      // for each segmentation class, check if it is labeled
      for (let i = 0; i < requiredSegmentationClasses?.length; i++) {
        const segmentationClass = requiredSegmentationClasses[i]

        const requiredPhasesForSegmentation = requiredPhases // TODO, see above
        const phasesAreRequiredForSegmentation = !!requiredPhasesForSegmentation?.length

        // case a) if phases are required, check if segmentation labels exist for each phase
        if (phaseLabelsAreRequiredForDicom && phasesAreRequiredForSegmentation) {
          for (let j = 0; j < requiredPhasesForSegmentation?.length; j++) {
            // first, get the phase label for the required phase
            const requiredPhaseValue = requiredPhasesForSegmentation[j]
            const phaseLabel = phaseLabels?.find(
              (phaseLabel) => phaseLabel?.dicom === dicom?.uuid && phaseLabel?.phaseClass === requiredPhaseValue,
            )

            // next, look for the segmentation label for the required phase
            const segmentationLabel = segmentationLabels?.find(
              (segmentationLabel) =>
                segmentationLabel?.frame === phaseLabel?.frame &&
                segmentationLabel?.segmentationClass === segmentationClass?.uuid,
            )

            // if the segmentationLabel label does not exist, return false
            if (!segmentationLabel)
              return {
                segmentationsAreLabeled: false,
                segmentationsAreRequired: true,
              }

            // if the segmentation is not fully visible, skip it
            if (segmentationLabel?.isFullyVisible === false) continue

            // if the segmentation paths are empty, return false
            if (!segmentationLabel?.paths?.length)
              return {
                segmentationsAreLabeled: false,
                segmentationsAreRequired: true,
              }
          }
        }

        // case b) if phases are not required, check if segmentation labels exist for the dicom
        else {
          const segmentationLabel = segmentationLabels?.find(
            (segmentationLabel) =>
              segmentationLabel?.dicom === dicom?.uuid &&
              segmentationLabel?.segmentationClass === segmentationClass?.uuid,
          )

          // if the segmentation label does not exist, return false
          if (!segmentationLabel)
            return {
              segmentationsAreLabeled: false,
              segmentationsAreRequired: true,
            }

          // if the segmentation is not fully visible, skip it
          if (segmentationLabel?.isFullyVisible === false) continue

          // if the segmentation paths are empty, return false
          if (!segmentationLabel?.paths?.length)
            return {
              segmentationsAreLabeled: false,
              segmentationsAreRequired: true,
            }
        }
      }

      return {
        segmentationsAreLabeled: true,
        segmentationsAreRequired: true,
      }
    },
    [phaseLabels, segmentationClasses, viewLabels, segmentationLabels],
  )

  const checkIfDicomIsLabeled = useCallback(
    (dicom) => {
      const { viewLabelIsLabeled, viewLabelIsRequired } = checkDicomForLabeledViewLabel(dicom)
      const { phaseLabelsAreLabeled, phaseLabelsAreRequired } = checkDicomForLabeledPhases(dicom)
      const { keypointCollectionLabelsAreLabeled, keypointCollectionLabelsAreRequired } =
        checkDicomForLabeledKeypoints(dicom)
      const { segmentationsAreLabeled, segmentationsAreRequired } = checkDicomForLabeledSegmentations(dicom)

      const dicomIsLabeled =
        viewLabelIsLabeled &&
        (phaseLabelsAreLabeled || !phaseLabelsAreRequired) &&
        (keypointCollectionLabelsAreLabeled || !keypointCollectionLabelsAreRequired) &&
        (segmentationsAreLabeled || !segmentationsAreRequired)

      return {
        viewLabelIsLabeled,
        viewLabelIsRequired,
        phaseLabelsAreLabeled,
        phaseLabelsAreRequired,
        keypointCollectionLabelsAreLabeled,
        keypointCollectionLabelsAreRequired,
        segmentationsAreLabeled,
        segmentationsAreRequired,
        dicomIsLabeled,
      }
    },
    [
      checkDicomForLabeledKeypoints,
      checkDicomForLabeledViewLabel,
      checkDicomForLabeledPhases,
      checkDicomForLabeledSegmentations,
    ],
  )

  const activeFrame = useMemo(() => frames?.[activeFrameIndex], [frames, activeFrameIndex])
  const activeDicom = useMemo(
    () => dicomLabelingJobsResponse?.find((dicomLabelingJob) => dicomLabelingJob?.uuid === dicomLabelingJobId).dicom,
    [dicomLabelingJobId, dicomLabelingJobsResponse],
  )
  const activeViewLabel = useMemo(
    () => viewLabels?.find((viewLabel) => viewLabel?.dicom === activeDicom?.uuid),
    [activeDicom, viewLabels],
  )
  const activeQualityLabel = useMemo(
    () => qualityLabels?.find((quality) => quality?.dicom === activeDicom?.uuid),
    [activeDicom, qualityLabels],
  )

  const activeCardiacPhases = useMemo(
    () => phaseClasses?.filter((cardiacPhase) => cardiacPhase.viewClasses?.includes(activeViewLabel?.viewClass)),
    [activeViewLabel?.viewClass, phaseClasses],
  )
  const activePhaseLabels = useMemo(() => {
    return phaseLabels?.filter((phaseLabel) => phaseLabel?.dicom === activeDicom?.uuid)
  }, [activeDicom, phaseLabels])
  const activeKeypointCollectionLabels = useMemo(
    () =>
      keypointCollectionLabels?.find(
        (keypointCollectionLabel) =>
          keypointCollectionLabel?.frame === activeFrame?.uuid &&
          keypointCollectionLabel?.keypointCollectionClass === activeKeypointCollectionClass?.uuid,
      ),
    [activeFrame?.uuid, activeKeypointCollectionClass?.uuid, keypointCollectionLabels],
  )

  const activeSegmentation = useMemo(
    () =>
      segmentationLabels?.find(
        (segmentation) =>
          segmentation?.frame === activeFrame?.uuid &&
          segmentation?.segmentationClass === activeSegmentationClass?.uuid,
      ),
    [segmentationLabels, activeFrame?.uuid, activeSegmentationClass?.uuid],
  )

  const allSegmentations = useMemo(
    () => segmentationLabels?.filter((segmentation) => segmentation?.frame === activeFrame?.uuid),
    [segmentationLabels, activeFrame?.uuid],
  )

  const viewClass = useMemo(
    () => viewClasses?.find((viewClass) => viewClass?.uuid === activeViewLabel?.viewClass),
    [activeViewLabel?.viewClass, viewClasses],
  )

  const dicomViewClasess = useMemo(
    () =>
      viewClasses?.filter(
        (viewClass) => viewClass?.type === activeDicom?.type && viewClass?.mediaType === activeDicom?.mediaType,
      ),
    [viewClasses, activeDicom],
  )
  const dicomKeypointCollectionClasses = useMemo(
    () =>
      keypointCollectionClasses?.filter((keypointCollectionClass) =>
        keypointCollectionClass?.viewClasses?.includes(viewClass?.uuid),
      ),
    [keypointCollectionClasses, viewClass?.uuid],
  )
  const dicomFeatures = useMemo(
    () => segmentationClasses?.filter((feature) => feature?.viewClasses?.includes(viewClass?.uuid)),
    [segmentationClasses, viewClass?.uuid],
  )

  const {
    viewLabelIsLabeled,
    phaseLabelsAreLabeled,
    keypointCollectionLabelsAreLabeled,
    segmentationsAreLabeled,
    dicomIsLabeled,
  } = checkIfDicomIsLabeled(activeDicom)

  const showPhaser = useMemo(() => !!activeCardiacPhases?.length, [activeCardiacPhases?.length])
  const showKeypointer = useMemo(
    () => !!dicomKeypointCollectionClasses?.length,
    [dicomKeypointCollectionClasses?.length],
  )
  const showSegmentor = useMemo(() => !!dicomFeatures?.length, [dicomFeatures?.length])

  const previousButtonIsDisabled = activeTool === 'identifier'
  const nextButtonIsDisabled =
    activeTool === 'identifier'
      ? showPhaser || showKeypointer || showSegmentor
        ? false
        : true
      : activeTool === 'phaser'
        ? showKeypointer || showSegmentor
          ? false
          : true
        : activeTool === 'keypointer'
          ? showSegmentor
            ? false
            : true
          : true

  const showWindowAndViewTag = useMemo(() => viewLabelIsLabeled, [viewLabelIsLabeled])
  const showPhaseTag = useMemo(
    () => showPhaser && showWindowAndViewTag && phaseLabelsAreLabeled,
    [phaseLabelsAreLabeled, showPhaser, showWindowAndViewTag],
  )
  const showKeypointTag = useMemo(
    () => showKeypointer && showPhaseTag && keypointCollectionLabelsAreLabeled,
    [keypointCollectionLabelsAreLabeled, showKeypointer, showPhaseTag],
  )
  const showSegmentationTag = useMemo(
    () => showSegmentor && showKeypointTag && segmentationsAreLabeled,
    [showSegmentor, showKeypointTag, segmentationsAreLabeled],
  )

  const windowAndViewTag = useMemo(
    () => `${viewClass?.window} ${viewClass?.view}`,
    [viewClass?.view, viewClass?.window],
  )
  const phaseTag = useMemo(() => `${activePhaseLabels?.length} Phases`, [activePhaseLabels?.length])
  const keypointTag = useMemo(() => {
    const phaseFrameUuids = activePhaseLabels?.map((phaseClass) => phaseClass?.frame)
    const keypointCollectionClassUuids = dicomKeypointCollectionClasses?.map(
      (keypointCollectionClass) => keypointCollectionClass?.uuid,
    )
    const labeledKeypointCollections = keypointCollectionLabels?.filter(
      (keypointCollectionLabel) =>
        phaseFrameUuids?.includes(keypointCollectionLabel?.frame) &&
        keypointCollectionClassUuids?.includes(keypointCollectionLabel?.keypointCollectionClass) &&
        !!keypointCollectionLabel?.keypointLabels?.length &&
        keypointCollectionLabel?.keypointLabels?.every((keypoint) => keypoint?.x && keypoint?.y),
    )
    return `${labeledKeypointCollections?.length} Keypoint Collections`
  }, [activePhaseLabels, dicomKeypointCollectionClasses, keypointCollectionLabels])
  const segmentationTag = useMemo(() => {
    const usablePhases = activePhaseLabels?.filter((phaseLabel) => phaseLabel?.phaseClass !== 'Mid-Systole')
    const phaseFrameUuids = usablePhases?.map((phaseClass) => phaseClass?.frame)
    const dicomFeatureValues = dicomFeatures?.map((feature) => feature?.value)
    const labeledSegmentations = segmentationLabels?.filter(
      (segmentation) =>
        phaseFrameUuids?.includes(segmentation?.frame) &&
        dicomFeatureValues?.includes(segmentation?.segmentationClass?.value) &&
        !!segmentation?.paths?.length,
    )
    return `${labeledSegmentations?.length} Segmentations`
  }, [activePhaseLabels, dicomFeatures, segmentationLabels])

  const changesAreSaved = useMemo(
    () =>
      viewLabelChangesAreSaved &&
      qualityLabelChangesAreSaved &&
      phaseLabelChangesAreSaved &&
      keypointCollectionChangesAreSaved &&
      segmentationChangesAreSaved &&
      dicomJobChangesAreSaved,
    [
      phaseLabelChangesAreSaved,
      keypointCollectionChangesAreSaved,
      viewLabelChangesAreSaved,
      qualityLabelChangesAreSaved,
      segmentationChangesAreSaved,
      dicomJobChangesAreSaved,
    ],
  )

  const handleDicomTypeOnClick = () => {
    const typeToggleMap = {
      Color: 'Standard',
      Standard: 'Color',
      'ICE Color': 'ICE Standard',
      'ICE Standard': 'ICE Color',
    }

    const type = typeToggleMap[dicom?.type] || 'Standard' // Fallback to 'Standard' if type is undefined

    setDicom((dicom) => ({
      ...dicom,
      type,
    }))
  }

  const setViewLabel = useCallback(
    (viewLabel) => {
      const nonActiveViewLabels = viewLabels?.filter((viewLabel) => viewLabel?.dicom !== activeDicom?.uuid)
      setViewLabels([...nonActiveViewLabels, viewLabel])
      setViewLabelChangesAreSaved(false)
    },
    [activeDicom, viewLabels],
  )

  const setActiveQualityLabel = useCallback(
    (qualityLabel) => {
      const nonActiveQualityLabels = qualityLabels?.filter((qualityLabel) => qualityLabel?.dicom !== activeDicom?.uuid)
      setQualityLabels([...nonActiveQualityLabels, qualityLabel])
      setQualityLabelChangesAreSaved(false)
    },
    [activeDicom, qualityLabels],
  )

  const setPhaseLabel = useCallback(
    (newPhaseLabel) => {
      const nonActivePhaseLabels = phaseLabels?.filter(
        (phaseLabel) =>
          phaseLabel?.frame !== activeFrame?.uuid &&
          !(newPhaseLabel?.phaseClass === phaseLabel?.phaseClass && newPhaseLabel?.dicom === phaseLabel?.dicom),
      )
      setPhaseLabels([...nonActivePhaseLabels, newPhaseLabel])
      setPhaseLabelLabelChangesAreSaved(false)
    },
    [activeFrame?.uuid, phaseLabels],
  )

  const setKeypointCollectionLabel = useCallback(
    (keypointCollectionLabel) => {
      const nonActiveKeypointCollections = keypointCollectionLabels?.filter(
        (keypointCollectionLabel) =>
          keypointCollectionLabel?.frame !== activeFrame?.uuid ||
          keypointCollectionLabel?.keypointCollectionClass !== activeKeypointCollectionClass?.uuid,
      )
      const newKeypointCollectionLabel = {
        ...keypointCollectionLabel,
        frame: activeFrame?.uuid,
        frameIndex: activeFrameIndex,
        keypointCollectionClass: activeKeypointCollectionClass?.uuid,
        keypointCollectionClassValue: activeKeypointCollectionClass?.value,
      }
      setKeypointCollectionLabelLabels([...nonActiveKeypointCollections, newKeypointCollectionLabel])
      setKeypointCollectionLabelLabelsChangesAreSaved(false)
    },
    [activeFrame?.uuid, activeFrameIndex, activeKeypointCollectionClass, keypointCollectionLabels],
  )

  const setSegmentation = useCallback(
    (newSegmentation) => {
      const nonActiveSegmentations = segmentationLabels?.filter(
        (segmentation) =>
          !(
            segmentation?.frame === activeFrame?.uuid &&
            segmentation?.segmentationClass === activeSegmentationClass?.uuid
          ),
      )
      setSegmentationLabels([
        ...nonActiveSegmentations,
        {
          ...newSegmentation,
          segmentationClass: activeSegmentationClass?.uuid,
          segmentationClassValue: activeSegmentationClass?.value,
          dicom: activeDicom?.uuid,
          frame: activeFrame?.uuid,
        },
      ])
      setSegmentationChangesAreSaved(false)
    },
    [
      segmentationLabels,
      activeSegmentationClass?.uuid,
      activeSegmentationClass?.value,
      activeDicom?.uuid,
      activeFrame?.uuid,
    ],
  )

  // keypointer tool:
  const activeKeypoints = useMemo(() => {
    const keypointLabels = activeKeypointCollectionClass?.keypointClasses?.map((keypointClass) => {
      const keypoint = activeKeypointCollectionLabels?.keypointLabels?.find(
        (keypoint) => keypoint?.keypointClass === keypointClass?.uuid,
      )
      const isLabeled = !!keypoint?.x && !!keypoint?.y
      const isActive =
        keypoint?.isActive === undefined && !isLabeled && keypointClass?.order === 1 ? true : keypoint?.isActive

      return {
        ...keypoint,
        order: keypointClass?.order,
        frame: activeFrame?.uuid,
        frameIndex: activeFrameIndex,
        keypointClass: keypointClass?.uuid,
        keypointClassValue: keypointClass?.value,
        keypointPair: keypointClass?.keypointPair,
        isActive,
        isLabeled,
      }
    })
    return keypointLabels
  }, [
    activeFrame?.uuid,
    activeFrameIndex,
    activeKeypointCollectionLabels?.keypointLabels,
    activeKeypointCollectionClass?.keypointClasses,
  ])

  const setKeypoints = useCallback(
    (keypointLabels) => {
      setKeypointCollectionLabel({
        ...activeKeypointCollectionLabels,
        keypointLabels: keypointLabels,
      })
    },
    [activeKeypointCollectionLabels, setKeypointCollectionLabel],
  )

  const playVideo = useCallback(
    (speed) => {
      setVideoState(speed)
      const fps = numberOfSeconds * speed
      const id = setInterval(() => {
        setActiveFrameIndex((activeFrameIndex) => {
          if (activeFrameIndex === numberOfFrames - 1) return 0
          return activeFrameIndex + 1
        })
      }, fps)
      setIntervalId(id)
    },
    [numberOfFrames, numberOfSeconds],
  )

  const pauseVideo = useCallback(() => {
    if (intervalId !== null) {
      setVideoState(0)
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }, [intervalId])

  useEffect(() => setActiveTool('identifier'), [dicomLabelingJobId])

  useEffect(() => {
    if (!dicomLabelingJobId) return
    pauseVideo()
    setActiveFrameIndex(0)
    setViewLabelChangesAreSaved(true)
    setQualityLabelChangesAreSaved(true)
    setPhaseLabelLabelChangesAreSaved(true)
    setKeypointCollectionLabelLabelsChangesAreSaved(true)
    setSegmentationChangesAreSaved(true)
    setDicomJobChangesAreSaved(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dicomLabelingJobId])

  useEffect(() => {
    if (!dicomLabelingJobsResponse | !dicomLabelingJobId) return
    setDicom(dicomLabelingJobsResponse?.find((dicom) => dicom?.dicom.uuid === activeDicom?.uuid)?.dicom)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dicomLabelingJobsResponse, dicomLabelingJobId])

  useEffect(() => {
    if (!framesResponse || !activeDicom) return
    setFrames(framesResponse?.filter((frame) => frame.dicom === activeDicom?.uuid))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [framesResponse, activeDicom])

  useEffect(() => {
    if (!studyId) return
    getViewLabels()
    getQualityLabels()
    getPhaseLabels()
    getKeypointCollections()
    getSegmentations()
  }, [studyId, getKeypointCollections, getPhaseLabels, getSegmentations, getViewLabels, getQualityLabels])

  useEffect(() => setViewLabels(viewLabelsResponse), [viewLabelsResponse])
  useEffect(() => setQualityLabels(qualityLabelsResponse), [qualityLabelsResponse])
  useEffect(() => setPhaseLabels(phaseLabelsResponse), [phaseLabelsResponse])
  useEffect(
    () => setKeypointCollectionLabelLabels(keypointCollectionLabelsResponse),
    [keypointCollectionLabelsResponse],
  )
  useEffect(() => setSegmentationLabels(segmentationLabelsResponse), [segmentationLabelsResponse])

  useEffect(
    () => setActiveKeypointCollectionClass(dicomKeypointCollectionClasses?.[0]),
    [dicomKeypointCollectionClasses],
  )
  useEffect(() => setActiveSegmentationClass(dicomFeatures?.[0]), [dicomFeatures])

  // wait 1 second for all components to mount
  useEffect(() => {
    const apisAreLoading =
      studyLabelingJobResponseIsLoading ||
      dicomLabelingJobsResponseIsLoading ||
      framesResponseIsLoading ||
      viewLabelResponseIsLoading ||
      qualityLabelsResponseIsLoading ||
      phaseLabelsResponseIsLoading ||
      keypointCollectionLabelsResponseIsLoading ||
      segmentationLabelsResponseIsLoading ||
      viewClassesResponseIsLoading ||
      phaseClassesResponseIsLoading ||
      keypointCollectionClassesResponseIsLoading ||
      segmentationClassesResponseIsLoading

    if (apisAreLoading) {
      setDataIsLoading(true)
      const apisLoaded = new Promise((resolve) => {
        const checkApis = () => (apisAreLoading ? setTimeout(checkApis, 1000) : resolve())
        checkApis()
      })

      const oneSecondWait = new Promise((resolve) => setTimeout(resolve, 1000))

      Promise.all([apisLoaded, oneSecondWait]).then(() => setDataIsLoading(false))
    } else setTimeout(() => setDataIsLoading(false), 1000)
  }, [
    viewClassesResponseIsLoading,
    viewLabelResponseIsLoading,
    qualityLabelsResponseIsLoading,
    phaseLabelsResponseIsLoading,
    keypointCollectionLabelsResponseIsLoading,
    segmentationLabelsResponseIsLoading,
    phaseClassesResponseIsLoading,
    keypointCollectionClassesResponseIsLoading,
    segmentationClassesResponseIsLoading,
    dicomLabelingJobsResponseIsLoading,
    framesResponseIsLoading,
    studyLabelingJobResponseIsLoading,
  ])

  useHotkeys('R', (event) => {
    event.preventDefault()
    if (previousButtonIsDisabled) return
    setActiveTool((activeTool) =>
      activeTool === 'phaser'
        ? 'identifier'
        : activeTool === 'keypointer'
          ? showPhaser
            ? 'phaser'
            : 'identifier'
          : activeTool === 'segmentor'
            ? showKeypointer
              ? 'keypointer'
              : showPhaser
                ? 'phaser'
                : 'identifier'
            : 'identifier',
    )
  })

  useHotkeys('Y', (event) => {
    event.preventDefault()
    if (nextButtonIsDisabled) return
    setActiveTool((activeTool) =>
      activeTool === 'identifier'
        ? showPhaser
          ? 'phaser'
          : showKeypointer
            ? 'keypointer'
            : showSegmentor
              ? 'segmentor'
              : 'identifier'
        : activeTool === 'phaser'
          ? showKeypointer
            ? 'keypointer'
            : showSegmentor
              ? 'segmentor'
              : 'identifier'
          : activeTool === 'keypointer'
            ? showSegmentor
              ? 'segmentor'
              : 'identifier'
            : 'identifier',
    )
  })

  const showPageLoader = dataIsLoading
  const showSavingLoader = dataIsSaving
  const showErrorIcon = !dataIsSaving && errorOccured
  const showUnsavedChanges = !dataIsSaving && !showErrorIcon && !changesAreSaved

  return (
    <ViewerContainer>
      <HeaderContainer>
        <Button>
          <a href={backToDatasetLink}>
            <IoMdArrowRoundBack />
            <span>Back to Dataset</span>
          </a>
        </Button>
        <LabelsContainer>
          {!showPageLoader && (
            <>
              <StatusTag isHidden={dataIsLoading} isLabeled={dicomIsLabeled}>
                {dicomIsLabeled ? 'Labeled' : ''}
              </StatusTag>
              {showSegmentationTag && <LabelTag type="segmentor">{segmentationTag}</LabelTag>}
              {showKeypointTag && <LabelTag type="keypointer">{keypointTag}</LabelTag>}
              {showPhaseTag && <LabelTag type="phaser">{phaseTag}</LabelTag>}
              {showWindowAndViewTag && <LabelTag type="identifier">{windowAndViewTag}</LabelTag>}
              <LabelTag onClick={handleDicomTypeOnClick} type="type">
                {dicom?.type}
              </LabelTag>
            </>
          )}
          <SmallLoaderContainer>
            <IconContainer isHidden={!showUnsavedChanges} size={30}>
              <IoIosCloudUpload color="#9b257b" />
            </IconContainer>
            <IconContainer isHidden={!showErrorIcon} size={30}>
              <IoIosWarning color="red" />
            </IconContainer>
            <IconContainer isHidden={!showSavingLoader}>
              <Oval
                visible={true}
                height="25"
                width="25"
                color="#222"
                secondaryColor="#ccc"
                ariaLabel="oval-loading"
                wrapperClass="SpinnerContainer"
                strokeWidth={4}
              />
            </IconContainer>
          </SmallLoaderContainer>
        </LabelsContainer>
      </HeaderContainer>
      <PageContainer>
        <SubHeader
          showPageLoader={showPageLoader}
          study={study}
          dicoms={dicoms}
          checkIfDicomIsLabeled={checkIfDicomIsLabeled}
          previousButtonIsDisabled={previousButtonIsDisabled}
          nextButtonIsDisabled={nextButtonIsDisabled}
          identifierIsDisabled={identifierIsDisabled}
          identifierIsActive={identifierIsActive}
          setActiveTool={setActiveTool}
          showPhaser={showPhaser}
          phaserIsDisabled={phaserIsDisabled}
          phaserIsActive={phaserIsActive}
          showKeypointer={showKeypointer}
          keypointerIsDisabled={keypointerIsDisabled}
          keypointerIsActive={keypointerIsActive}
          showSegmentor={showSegmentor}
          segmentorIsDisabled={segmentorIsDisabled}
          segmentorIsActive={segmentorIsActive}
          dicom={dicom}
          frames={frames}
          activePhaseLabels={activePhaseLabels}
          keypointCollectionLabels={keypointCollectionLabels}
          segmentations={segmentationLabels}
          activeViewLabel={activeViewLabel}
          activeQualityLabel={activeQualityLabel}
          setDataIsSaving={setDataIsSaving}
          setErrorOccured={setErrorOccured}
          setViewLabelChangesAreSaved={setViewLabelChangesAreSaved}
          setQualityLabelChangesAreSaved={setQualityLabelChangesAreSaved}
          setPhaseLabelLabelChangesAreSaved={setPhaseLabelLabelChangesAreSaved}
          setKeypointCollectionLabelLabelsChangesAreSaved={setKeypointCollectionLabelLabelsChangesAreSaved}
          setSegmentationChangesAreSaved={setSegmentationChangesAreSaved}
          setDicomJobChangesAreSaved={setDicomJobChangesAreSaved}
          dicomLabelingJob={dicomLabelingJobId}
        />
        <ContentContainer>
          {/*<SideBarContainer isHidden={!showPageLoader} style={{ minWidth: '300px', maxWidth: '300px' }} />*/}
          <RightSideBar isHidden={showPageLoader} isOpen={dicomSideBarIsOpen}>
            <SideBarContainer>
              <Dicoms
                dicomLabelingJobs={dicomLabelingJobsResponse}
                checkIfDicomIsLabeled={checkIfDicomIsLabeled}
                changesAreSaved={changesAreSaved}
                dicomSideBarIsOpen={dicomSideBarIsOpen}
                setDicomSideBarIsOpen={setDicomSideBarIsOpen}
                dicomLabelingJobId={dicomLabelingJobId}
              />
            </SideBarContainer>
          </RightSideBar>
          <CanvasContainer isHidden={!showPageLoader}>
            <Loader />
          </CanvasContainer>
          <CanvasContainer isHidden={showPageLoader}>
            <Canvas
              study={study}
              dicom={dicom}
              dicomLabelingJobs={dicomLabelingJobs}
              changesAreSaved={changesAreSaved}
              frames={frames}
              playVideo={playVideo}
              pauseVideo={pauseVideo}
              videoState={videoState}
              activeFrame={activeFrame}
              activeKeypoints={activeKeypoints}
              phaseLabels={activePhaseLabels}
              setFrames={setFrames}
              setActiveFrame={setActiveFrameIndex}
              setKeypoints={setKeypoints}
              showMeasurements={showMeasurements}
              keypointerIsActive={keypointerIsActive}
              segmentorIsActive={segmentorIsActive}
              activeSegmentation={activeSegmentation}
              allSegmentations={allSegmentations}
              setActiveSegmentation={setSegmentation}
              useOpenContours={useOpenContours}
              showOtherSegmentations={showOtherSegmentations}
            />
          </CanvasContainer>
          {/*<SideBarContainer isHidden={!showPageLoader} style={{ minWidth: '300px', maxWidth: '300px' }} />*/}
          <RightSideBar isHidden={showPageLoader} isOpen>
            {identifierIsActive && (
              <Qualifier dicom={dicom} qualityLabel={activeQualityLabel} setQualityLabel={setActiveQualityLabel} />
            )}
            <SideBarContainer isHidden={showPageLoader}>
              {identifierIsActive && (
                <Identifier
                  dicom={dicom}
                  viewClasses={dicomViewClasess}
                  viewLabel={activeViewLabel}
                  setViewLabel={setViewLabel}
                />
              )}
              {showPhaser && phaserIsActive && (
                <Phaser
                  activeFrame={activeFrame}
                  dicom={dicom}
                  phaseClasses={activeCardiacPhases}
                  phaseLabels={activePhaseLabels}
                  setPhaseLabel={setPhaseLabel}
                  setActiveTool={setActiveTool}
                />
              )}
              {showKeypointer && keypointerIsActive && (
                <Keypointer
                  activeFrameIndex={activeFrameIndex}
                  activeFrame={activeFrame}
                  phaseLabels={activePhaseLabels}
                  activeKeypoints={activeKeypoints}
                  keypointCollectionClasses={dicomKeypointCollectionClasses}
                  activeKeypointCollectionClass={activeKeypointCollectionClass}
                  keypointCollectionLabels={keypointCollectionLabels}
                  showMeasurements={showMeasurements}
                  showPhaser={showPhaser}
                  setActiveFrameIndex={setActiveFrameIndex}
                  setActiveKeypointCollectionClass={setActiveKeypointCollectionClass}
                  setKeypoints={setKeypoints}
                  setShowMeasurements={setShowMeasurements}
                />
              )}
              {showSegmentor && segmentorIsActive && (
                <Segmentor
                  activeFrame={activeFrame}
                  activeSegmentationClass={activeSegmentationClass}
                  activeSegmentation={activeSegmentation}
                  segmentationClasses={dicomFeatures}
                  dicom={dicom}
                  phaseLabels={activePhaseLabels}
                  segmentations={segmentationLabels}
                  setActiveSegmentationClass={setActiveSegmentationClass}
                  setActiveFrameIndex={setActiveFrameIndex}
                  setActiveSegmentation={setSegmentation}
                  setSegmentation={setSegmentation}
                  useOpenContours={useOpenContours}
                  setUseOpenContours={setUseOpenContours}
                  showOtherSegmentations={showOtherSegmentations}
                  setShowOtherSegmentations={setShowOtherSegmentations}
                />
              )}
            </SideBarContainer>
          </RightSideBar>
        </ContentContainer>
      </PageContainer>
    </ViewerContainer>
  )
}

export default Viewer
